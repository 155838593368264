import * as React from 'react';
import Layout from '../components/layout';
import Separator from '../components/separator';
import { StaticImage } from 'gatsby-plugin-image';
import Seo from '../components/seo';

// markup
const IndexPage = () => {
  return (
    <Layout>
      <Seo
        title="Главная"
        description="Касается ли Бог вашего сердца? Готовы ли вы к настоящему миру, который приходит только через отношения с Богом?"
      />
      <section className="hero">
        <h1 className="title is-2">Найдите свой путь к миру</h1>
        <StaticImage
          style={{
            position: 'absolute',
            boxShadow: 'inset 0 0 0 2000px rgb(0 0 0 / 45%',
          }}
          loading="eager"
          src="../images/path-green-banner.jpg"
          alt=""
          className="backgroundImage"
        />
      </section>
      <div className="content-wrapper underline">
        <div className="content-copy">
          <section>
            <p>
              Беспокоитесь ли вы о будущем? Боритесь ли вы с личными или
              духовными проблемами, которые вызывают беспокойство, страх и
              неуверенность в завтрашнем дне? Здесь вы можете просмотреть статьи
              на различные темы и найти ответы на различные вопросы, начать свой
              путь к внутреннему миру.
            </p>
            <a href="/temi/" className="button">
              Ищете Ответы?
            </a>
          </section>
          <section>
            <p>
              Возможно, Бог начинает работать в вашем сердце, и вы готовы
              получить настоящий мир, который не заканчивается, который можно
              найти только через отношения с единственным Богом. Сделайте шаг
              навстречу Ему сегодня.
            </p>
            <a href="/shagi/" className="button">
              Начните новую жизнь с Иисусом.
            </a>
          </section>
          <Separator />
          <section>
            <h2 className="subtitle is-3">Что такое Мир?</h2>
            <p>
              Большинство людей стремится достичь лишь одного – мира. Люди ищут
              мир, потому что у них нет свободы или утешения. Людям как будто не
              по себе. Библия описывает мир в трех аспектах.
            </p>
            <ol>
              <li>
                <strong>Психологический мир</strong>, внутренний покой.
              </li>
              <li>
                <strong>Мир в отношениях</strong>, гармония среди человечества.
              </li>
              <li>
                <strong>Духовный мир</strong>, между Богом и человеком.
              </li>
            </ol>
            <p>
              Библия описывает как грех уничтожает все три вида мира.
              Сотворенный Богом человек, был в мире с Творцом, с собой и с
              окружающими. Но этот мир был потерян, когда человек обернулся
              против Бога. Этот мир можно восстановить, но мы не можем это
              осуществить собственными руками. Бог проложил для нас путь к миру.
            </p>
            <p>
              Иисус Христос, единственный Божий Сын, был послан к нам на землю
              чтобы забрать наши грехи. Он сделал это позволив людям распять
              Его, из-за этой жертвы появилась возможность получить мир –
              внутренний мир, мир с окружающими нас людьми и мир с Богом.
            </p>
            <p>
              После того как Иисус умер на кресте, Он был воскрешён. Это
              показало нам что Иисус действительно Бог, но также стало очевидно,
              что Он желает, чтобы у каждого человека был совершенный мир. «
              <em>
                Ибо Он есть мир наш… И, придя, благовествовал мир вам, дальним и
                близким
              </em>
              » (К Ефесянам 2:14,17).
            </p>
            <p>
              Иисус даёт нам силы преодолеть любой грех. Несмотря на сложность
              вашей ситуации, Христос может ее изменить. Но сперва вы должны
              осознать свои грехи и посвятить свою жизнь Иисусу, только тогда вы
              можете обрести совершенный мир.
            </p>
            <p>
              Иисус сказал, «
              <em>Сие сказал Я вам, чтобы вы имели во Мне мир</em>» (От Иоанна
              16:33). Чтобы иметь мир вы должны знать Того, кто может дать eго.{' '}
            </p>
            <a href="/shagi/" className="button">
              Готовы ли вы принять мир,
              <br />
              который дает Иисус?
            </a>
          </section>
          <Separator />
        </div>
        <div className="search-copy">
          <section className="content underline">
            <h3>Часто задаваемые вопросы</h3>
            <ul>
              <li>
                <a href="/v-chem-smysl-zhizni/">В чем смысл жизни?</a>
              </li>
              <li>
                <a href="/chto-budet-posle-smerti/">Что будет после смерти?</a>
              </li>
              <li>
                <a href="/kak-izbavitsya-ot-trevozhnosti/">
                  Как избавиться от тревожности?
                </a>
              </li>
            </ul>
            <h3>Текущие Поиски</h3>
            <ul>
              <li>
                <a href="/kak-perezhit-smert-blizkogo/">
                  Как пережить смерть близкого
                </a>
              </li>
              <li>
                <a href="/kak-izmenit-sebya/">Как изменить себя</a>
              </li>
              <li>
                <a href="/menya-pugaet-voina/">Меня пугает война</a>
              </li>
            </ul>
            <p>
              Ищите ли вы ответы на другие вопросы? Вы можете просмотреть
              каталог всех <a href="/temi/">вопросов и тем</a>.
            </p>
          </section>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
